import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DatePickerComponent } from 'src/app/shared/components/date-picker/date-picker.component';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { endpoints } from '../../../core/config/endpoints';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.css'],
})
export class StudentComponent implements OnInit {
  @ViewChild(DatePickerComponent, { static: true }) daterangepicker: DatePickerComponent | any;
  @ViewChild('closebutton') closebutton:any;
  @ViewChild('fileInput') el: ElementRef | any;
  myForm:FormGroup |any
  rangeForm: FormGroup | any;
  isTouchUIActivated = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  page: any;
  pagenext:any
  itemPerPage = 50;
  studentlist: any = [];
  Inactivelist: any = [];
  searchText: any;
  pagecounts: any;
  settings: any = [];
  student: any = [];
  submitted: boolean = false;
  selectedDate: any;
  modalRef:any

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    public formBuilder: FormBuilder,
    private router: Router,
    private AuthService: AuthService,
    public modalService: NgbModal,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.pagecounts = this.Apiservice.pageCounts;
    this.settings = this.AuthService.setting;
    this.student = this.settings.data.filter((t: any) => t.group == 'student');
    this.getStudent();
    this.rangeForm = this.formBuilder.group({
      start_time: ['', Validators.required],
      end_time: ['', Validators.required],
    });
    this.myForm=this.formBuilder.group({
      file:['']
    })
    
    
  }
  get ff() {
    return this.myForm.controls;
  }
  fileupload(event:any) {
    const reader = new FileReader(); 
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileUploadsubmit(file);
      this.closebutton.nativeElement.click();
    }
    
  }
  fileUploadsubmit(result:any) {
    const formData = new FormData();
    formData.append('file', result);
    this.Apiservice.postApi('student/excel-add', formData).subscribe(
      (data: any) => {
          this.toastr.success(data.message);
          this.getStudent();
          this.myForm.reset()
      },
      (error: any) => {
        this.toastr.error(error.error.message);
        this.getStudent();
        this.myForm.reset()
      }
    );
  }

  getStudent() {
    
    this.Apiservice.getApi(endpoints.studentlist.path).subscribe(
      (data: any) => {
        this.studentlist = data.filter((s: any) => s.status == 1);
        
        this.Inactivelist = data.filter((s: any) => s.status == 0);
      },
      (err: any) => {
        this.toaster.error(err.error.message)
      }
    );
  }
  private convert(str:any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  OnSelect(event:any){
    if(event?.startDate){
      let startdate=this.convert(event.startDate._d)
      let enddate=this.convert(event.endDate._d)
      this.rangeForm.controls['start_time'].setValue(startdate)
      this.rangeForm.controls['end_time'].setValue(enddate)
    }
  }
  deleteRow(i: any, student: any) {
    Swal.fire({
      title: this.translate.instant('Inactive'),
      text: this.translate.instant('Do you want to Inactive ?'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText:this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.Apiservice.getApi(
          endpoints.deletestudent.path + '?id=' + student.id
        ).subscribe(
          (data: any) => {
            Swal.fire({
              title:data.message,
              icon: 'success',
            });
            this.getStudent();
          },
          (err) => {
            Swal.fire({
              title: err,
              icon: 'error',
            });
          }
        );
      }
    });
  }
  deleteActiveRow(i: any, student: any) {
    Swal.fire({
      title: this.translate.instant('Active'),
      text: this.translate.instant('Do you want to Active ?'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText:this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.Apiservice.getApi(
          endpoints.activestudent.path + '?id=' + student.id
        ).subscribe(
          (data: any) => {
            Swal.fire({
              title: data.message,
              icon: 'success',
            });
            this.getStudent();
          },
          (err) => {
            Swal.fire({
              title: err,
              icon: 'error',
            });
          }
        );
      }
    });
  }
  get f() {
    return this.rangeForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if(this.rangeForm.invalid){
      this.toaster.error(this.translate.instant('Select a from and to date'))
      return
    }
    this.Apiservice.getApi(endpoints.studentlist.path+"?start_time="+this.rangeForm.value.start_time+"&end_time="+this.rangeForm.value.end_time).subscribe(
      (data: any) => {
        this.studentlist = data.filter((s: any) => s.status == 1);
        this.Inactivelist = data.filter((s: any) => s.status == 0);
        this.toaster.success(this.translate.instant('Successfully'))
        this.rangeForm.reset()
      },
      (err: any) => {
        this.toaster.error(err.error.message)
      }
    );
  }
  clear(){
    this.daterangepicker.clear()
    this.rangeForm.reset()
    this.getStudent()
  }
  resetsearch() {
    this.searchText = '';
  }
  pageChange(event: any) {
    this.page = event;
  }
  pagechange(event: any) {
    this.pagenext = event;
  }
  
}
