<app-header></app-header>
<div id="main-content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row clearfix">
        <div class="col-xl-12 col-md-12 col-sm-12">
          <h2 translate>Belt</h2>
        </div>
        <div class="col-md-12">
          <div class="float-right">
            <a class="btn-sm auth-button" [routerLink]="['/admin/belt/add-belt']" translate><i
                class="fas fa-plus mr-1"></i>New</a>
          </div>
        </div>
      </div>
      <div class="row clearfix table mt-4">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="pb-3">
            <ul class="nav nav-pills" id="nav-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
                  aria-controls="pills-home" aria-selected="true" translate><i class="fas fa-lock-open"></i>
                  Active</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
                  aria-controls="pills-profile" aria-selected="false" translate><i class="fas fa-lock"></i>
                  In-active</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-belt-tab" data-toggle="pill" href="#pills-belt" role="tab"
                  aria-controls="pills-belt" aria-selected="false" translate><i class="fas fa-fill"></i>
                  Belt</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <div class="card">
                <div class="row">
                  <div class="col-md-12" id="content">
                    <div class="body">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="d-flex">{{ 'Show' | translate}}
                            <select [(ngModel)]="itemPerPage" name="selection-datatable_length"
                              aria-controls="selection-datatable"
                              class="custom-select custom-select-sm form-control form-control-sm w-auto">
                              <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                                {{pageCountObj}}</option>
                            </select> {{ 'entries' | translate}}
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="input-group">
                            <input type="text" class="form-control" placeholder="{{'Search'|translate}}"
                              [(ngModel)]="searchText" name="searchText">
                            <div class="input-group-append" *ngIf="searchText">
                              <span class="input-group-text" id="search-mail" (click)="resetsearch()"
                                translate>Clear</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th translate>Name</th>
                            <th translate>Color</th>
                            <th translate>Minimal Number of Class</th>
                            <th translate>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let list of ActiveStatus | paginate: { itemsPerPage: itemPerPage, currentPage: page, id:'active' } | filter: searchText; let i = index">
                            <td>{{list.name}}</td>
                            <td>{{list.color}}</td>
                            <td>{{list.range}}</td>
                            <td>
                              <a [routerLink]="['/admin/belt/edit-belt/'+list.id]" class="btn btn-default btn-sm"><i
                                  class="fas fa-pencil-alt"></i></a>
                              <a (click)="deleteActiveRow(i, list)" class="btn btn-default btn-sm ml-1"
                                title="{{ 'Delete' | translate }}"><i class="fas fa-trash-alt"></i></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mx-2">
                <pagination-controls 
                  id="active" 
                  (pageChange)="pageChange($event)"
                  [previousLabel]="'Previous' | translate"
                  [nextLabel]="'Next' | translate"
                ></pagination-controls>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
              <div class="card">
                <div class="row">
                  <div class="col-md-12">
                    <div class="body">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="d-flex">{{ 'Show' | translate}}
                            <select [(ngModel)]="itemPerPage" name="selection-datatable_length"
                              aria-controls="selection-datatable"
                              class="custom-select custom-select-sm form-control form-control-sm w-auto">
                              <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                                {{pageCountObj}}</option>
                            </select> {{ 'entries' | translate}}
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="input-group">
                            <input type="text" class="form-control" placeholder="{{'Search'|translate}}"
                              [(ngModel)]="searchText" name="searchText">
                            <div class="input-group-append" *ngIf="searchText">
                              <span class="input-group-text" id="search-mail" (click)="resetsearch()"
                                translate>Clear</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="table-responsive">
                      <table class="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th translate>Name</th>
                            <th translate>Color</th>
                            <th translate>Range</th>
                            <th translate>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let list of InActiveStatus | paginate: { itemsPerPage: itemPerPage, currentPage: pagenext, id:'inactive' } | filter: searchText; let i = index">
                            <td translate>{{list.name}}</td>
                            <td translate>{{list.color}}</td>
                            <td translate>{{list.range}}</td>
                            <td>
                              <a [routerLink]="['/admin/packages/edit-packages/'+list.id]"
                                class="btn btn-default btn-sm"><i class="fas fa-pencil-alt"></i></a>
                              <a (click)="deleteInActiveRow(i, list)" class="btn btn-default btn-sm ml-1"
                                title="{{ 'Delete' | translate }}"><i class="fas fa-trash-alt"></i></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mx-2">
                <pagination-controls 
                  id="inactive" 
                  (pageChange)="pagechange($event)"
                  [previousLabel]="'Previous' | translate"
                  [nextLabel]="'Next' | translate"
                ></pagination-controls>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-belt" role="tabpanel" aria-labelledby="pills-belt-tab">
              <div class="card">
                <div class="row">
                  <div class="col-md-12">
                    <div class="body">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="d-flex">{{ 'Show' | translate}}
                            <select [(ngModel)]="itemPerPage" name="selection-datatable_length"
                              aria-controls="selection-datatable"
                              class="custom-select custom-select-sm form-control form-control-sm w-auto">
                              <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                                {{pageCountObj}}</option>
                            </select> {{ 'entries' | translate}}
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="input-group">
                            <input type="text" class="form-control" placeholder="{{'Search'|translate}}"
                              [(ngModel)]="searchText" name="searchText">
                            <div class="input-group-append" *ngIf="searchText">
                              <span class="input-group-text" id="search-mail" (click)="resetsearch()"
                                translate>Clear</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="table-responsive">
                      <table class="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th translate>Name</th>
                            <th translate>Color</th>
                            <th translate>Completed</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let list of beltHistory | paginate: { itemsPerPage: itemPerPage, currentPage: pagenext } | filter: searchText; let i = index">
                            <td translate>{{list.student.first_name}} {{list.student.middle_name}} {{list.student.last_name}}</td>
                            <td translate>{{list.belt.color}}</td>
                            <td translate>{{list.date}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mx-2">
                <pagination-controls 
                  id="inactive" 
                  (pageChange)="pagechange($event)"
                  [previousLabel]="'Previous' | translate"
                  [nextLabel]="'Next' | translate"
                ></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
