<div id="left-sidebar" class="sidebar light_active">
  <button type="button" (click)="cToggoleMenu()" class="btn-toggle-offcanvas btn btn-sm float-right p-3">
    <i class="fas fa-times"></i>
  </button>
  <div class="navbar-brand">
    <a><img src="../../../../assets/img/tatami-logo.png" width="50" alt="" class="img-fluid logo" /> </a>
  </div>
  <div class="user-account profilepointer" [routerLink]="['/admin/profile']">
    <div class="user_div">
      <img src="assets/images/user.png" class="user-photo" alt="User Profile Picture" />
    </div>
    <div class="dropdown">
      <span translate>Profile</span>
      <a href="javascript:void(0);" class="user-name"><strong translate>Admin</strong></a>
    </div>
  </div>
  <nav id="left-sidebar-nav" class="sidebar-nav">
    <ul id="main-menu" class="metismenu animation-li-delay">
      <li>
        <a [routerLink]="['/admin/dashboard']"><i class="fas fa-desktop"></i><span translate>Dashboard</span></a>
      </li>
      <li>
        <a [routerLink]="['/admin/school']"><i class="fas fa-school"></i><span translate>School</span></a>
      </li>
      <li>
        <a [routerLink]="['/admin/events']"><i class="fas fa-calendar-check"></i><span translate>Class</span></a>
      </li>
      <li>
        <a [routerLink]="['/admin/student']"><i class="fas fa-user-graduate"></i><span translate>Student</span></a>
      </li>
      <li>
        <a [routerLink]="['/admin/payment']"><i class="fas fa-credit-card"></i><span translate>Payments</span></a>
      </li>
      <li>
        <a [routerLink]="['/admin/packages']"
          ><i class="fas fa-box"></i><span translate>Membership Packages</span></a
        >
      </li>
      <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <a
          class="settings-li"
          (click)="SalesCollapsed = !SalesCollapsed"
          [attr.aria-expanded]="!SalesCollapsed"
          aria-controls="collapseBasic"
          ><span translate>Settings</span><span class="plusicon" *ngIf="SalesCollapsed">+</span
          ><span class="plusicon" *ngIf="!SalesCollapsed">-</span><i class="fas fa-hand-holding-usd"></i>
        </a>
        <ul class="list-dot" id="collapseBasic" [collapse]="SalesCollapsed" [isAnimated]="true">
          <li>
            <a [routerLink]="['/admin/staff']"><i class="fas fa-user-circle"></i><span translate>Staff</span></a>
          </li>
          <li>
            <a [routerLink]="['/admin/class']"><i class="fas fa-home"></i><span translate>Program</span></a>
          </li>
          <li>
            <a [routerLink]="['/admin/video-category']"
              ><i class="fas fa-video"></i><span translate>Video Category</span></a
            >
          </li>
          <li>
            <a [routerLink]="['/admin/belt']"><i class="fas fa-fill"></i><span translate>Belt</span></a>
          </li>
        </ul>
      </li>
      <li>
        <a [routerLink]="['/admin/video-library']"
          ><i class="fas fa-video"></i><span translate>Video Library</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/admin/coach']"><i class="fas fa-user"></i><span translate>Coach</span></a>
      </li>
      <!-- <li>
                <a [routerLink]="['/admin/belt']"><i class="fas fa-fill"></i><span translate>Belt</span></a>
            </li> -->
      <li>
        <a [routerLink]="['/admin/messages']"><i class="fas fa-envelope"></i><span translate>Messages</span></a>
      </li>
      <li>
        <a [routerLink]="['/admin/notification']"
          ><i class="fas fa-bell"></i><span translate>Notification</span></a
        >
      </li>
      <!-- <li>
                <a [routerLink]="['/admin/report']"><i class="fas fa-file"></i><span translate>Report</span></a>
            </li> -->
    </ul>
  </nav>
  <ul class="action-list" *ngIf="isMobile">
    <li>
      <a href="javascript:void(0)" data-toggle="dropdown" aria-expanded="false"
        ><i class="fas fa-globe"></i><span translate></span
      ></a>
      <div class="dropdown-menu language-dropdown-menu mt-3" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item language-dropdown-items" (click)="useLanguage('en')">English</a>
        <a class="dropdown-item language-dropdown-items" (click)="useLanguage('es')">Spanish</a>
        <a class="dropdown-item language-dropdown-items" (click)="useLanguage('pt-br')">Brazilian portuguese</a>
      </div>
    </li>
    <li>
      <a (click)="logOut()" href="javascript:void(0)"><i class="fa fa-power-off"></i><span translate></span></a>
    </li>
  </ul>
</div>
