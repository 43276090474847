import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { endpoints } from 'src/app/core/config/endpoints';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-belt',
  templateUrl: './belt.component.html',
  styleUrls: ['./belt.component.css'],
})
export class BeltComponent implements OnInit {
  rangeForm: FormGroup | any;
  isTouchUIActivated = false;
  loading = false;
  success: boolean = false;
  searchText: any;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  page: any;
  pagenext:any
  pagecounts: any;
  itemPerPage = 50;
  liststaff: any = [];
  ActiveStatus: any = [];
  InActiveStatus: any = [];
  submitted: boolean = false;
  beltHistory:any = [];
  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    public formBuilder: FormBuilder,
    private router: Router,
    private AuthService: AuthService,
    private translate: TranslateService
  ) {}
  ngOnInit(): void {
    this.pagecounts = this.Apiservice.pageCounts;
    this.BeltList();
    this.beltHistort();
    this.rangeForm = this.formBuilder.group({
      start: [''],
      end: [''],
    });
  }
  BeltList() {
    this.Apiservice.getApi(endpoints.beltlist.path).subscribe((data: any) => {
      this.ActiveStatus = data.data.filter((s: any) => s.status == 1);
      this.InActiveStatus = data.data.filter((s: any) => s.status == 0);
    });
  }
  beltHistort(){
    this.Apiservice.getApi(endpoints.belthistory.path).subscribe((data:any) => {
      this.beltHistory = data.data;
      console.log('DATA', this.beltHistory);
    })
  }
  deleteActiveRow(i: number, school: any) {
    Swal.fire({
      title: this.translate.instant('Delete'),
      text: this.translate.instant('Do you want to Inactive ?'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText:this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.Apiservice.getApi(
          endpoints.deletebelt.path + '?id=' + school.id
        ).subscribe(
          (data: any) => {
            Swal.fire({
              title:  this.translate.instant('Status Changed Succesfully'),
              icon: 'success',
            });
            this.BeltList();
          },
          (err) => {


            Swal.fire({
              title:  this.translate.instant('Invalid Checked In'),
              icon: 'error',
            });
          }
        );
      }
    });
  }
  deleteInActiveRow(i: number, school: any) {
    Swal.fire({
      title: this.translate.instant('Active'),
      text: this.translate.instant('Do you want to Active ?'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText:this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.Apiservice.getApi(
          endpoints.activatebelt.path + '?id=' + school.id
        ).subscribe(
          (data: any) => {
            Swal.fire({
              title: this.translate.instant('Status Changed Succesfully'),
              icon: 'success',
            });
            this.BeltList();
          },
          (err) => {

            Swal.fire({
              title: this.translate.instant('Invalid Checked In'),
              icon: 'error',
            });
          }
        );
      }
    });
  }
  onSubmit() {
    this.submitted = true;

    if (this.rangeForm.invalid) {
      return;
    }
    this.Apiservice.postApi(
      endpoints.beltlist.path,
      this.rangeForm.value
    ).subscribe(
      (data: any) => {
        this.showMsg = true;
      },
      (err: any) => {

      }
    );
  }
  resetsearch() {
    this.searchText = '';
  }
  pageChange(event: any) {
    this.page = event;
  }
  pagechange(event: any) {
    this.pagenext = event;
  }
}
