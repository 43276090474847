import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { endpoints } from '../../core/config/endpoints';
import { ApiService } from './api.services';

export interface IAuthRegisterData {
  school_name: string;
  name: string;
  email: string;
  password: string;
  phone_code: string;
  phone_number: string;
}

interface IAuthRegisterResponse {
  token: string;
  expires_at: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  setting: any = [];
  schoolid: any;
  userid: any;
  assignclass: any = [];
  eventid: any;
  userrole: any;
  photo: any;
  schoolname: any;
  packageid: any;
  firstpassword: boolean = false;
  // userLogin:any;
  // userBankAccount:any;

  constructor(
    private _httpClient: HttpClient,
    private router: Router,
    public toaster: ToastrService,
    private ngxLoader: NgxUiLoaderService,
    private apiService: ApiService
  ) {
    let user: any = localStorage.getItem('currentUser');
    if (user) {
      user = JSON.parse(user);
      this.userid = user.user_id;
      this.userrole = user.user_role_name;
      this.photo = user.photo;

      if (user.user_role_name != 'SUPERADMIN') {
        this.schoolid = user.school.id;
        this.schoolname = user.school.name;
      }
    }
  }

  register(data: IAuthRegisterData) {
    return this._httpClient
      .post<IAuthRegisterResponse>(`${environment.BACKEND_BASE_URL}${endpoints.register.path}`, data)
      .pipe(switchMap(() => this.login(data.email, data.password, '', '')));
  }

  login(email: string, password: string, deviceId: string, devices: string) {
    return new Observable((observer) => {
      this._httpClient
        .post(`${environment.BACKEND_BASE_URL}${endpoints.logIn.path}`, {
          email,
          password,
          deviceId,
          devices,
        })
        .subscribe(
          (response: any) => {
            if (response && response.user_role_name != 'SUPERADMIN') {
              this.schoolid = response.school.id;
              this.schoolname = response.school.name;
            }
            this.photo = response.photo;
            this.userid = response.user_id;
            this.userrole = response.user_role_name;
            localStorage.setItem('currentUser', JSON.stringify(response));
            let data: any = localStorage.getItem('currentUser');
            //  this.userLogin = JSON.parse(data);
            // this.userBankAccount = this.userLogin.school.bankAccount;
            observer.next(response);
          },
          (err) => {
            observer.error(err);
          }
        );
    });
  }

  logOut() {
    localStorage.removeItem('currentUser');
    this.router.navigate(['/auth/login']);
  }

  isConnected() {
    return !!localStorage.getItem('currentUser');
  }
  loader() {
    this.ngxLoader.start();
    setTimeout(() => {
      this.ngxLoader.stop();
    }, 2000);
  }
}
