<app-header></app-header>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header">
            <div class="row clearfix">
                <div class="col-xl-12 col-md-12 col-sm-12">
                    <h2 *ngIf="!routerid" translate>Add Belt</h2>
                    <h2 *ngIf="routerid" translate>Edit Belt</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="float-right mb-4">
                        <a class="btn-sm auth-button " [routerLink]="['/admin/belt']"><i class="fas fa-bars mr-1"
                                translate></i>All</a>
                    </div>
                </div>
            </div>
            <div class="row clearfix mt-4">
                <div class="col-lg-8 col-md-8 col-sm-8 p-5 auth-form" style="margin: auto;">
                    <form [formGroup]="packageform" (ngSubmit)="onSubmit()">
                        <div class="form-group ">
                            <label class="mt-1" translate>Name<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="{{'Name'| translate}}" maxlength="20"
                                formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required" translate>Name is required</div>
                                <div *ngIf="f.name.errors.pattern" translate>Only Letters are allowed</div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <label class="mt-1" translate>Color<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="{{'Color'| translate}}" maxlength="20"
                                formControlName="color" [ngClass]="{ 'is-invalid': submitted && f.color.errors }" />
                            <div *ngIf="submitted && f.color.errors" class="invalid-feedback">
                                <div *ngIf="f.color.errors.required" translate>Color is required</div>
                                <div *ngIf="f.color.errors.pattern" translate>Only Letters are allowed</div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <label class="mt-1" translate>Minimal Number of Class<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="{{'Minimal Number of Class'| translate}}" maxlength="3"
                                formControlName="range"/>
                            <div *ngIf="submitted && f.range.errors" class="invalid-feedback">
                                <div *ngIf="f.range.errors.pattern" translate>Only Numbers are allowed</div>
                            </div>
                        </div>
                        <div class="form-group ">
                          <label class="mt-1" translate>Time Line</label>
                          <input type="text" class="form-control" placeholder="Time Line"
                              formControlName="time_line" />
                      </div>
                        <button type="submit" *ngIf="!routerid" class="btn btn-dark float-right auth-button mt-4"
                            translate>Save</button>
                        <button type="submit" *ngIf="routerid" class="btn btn-dark float-right auth-button mt-4"
                            translate>Update</button>
                        <a [routerLink]="['/admin/belt']" class="btn btn-dark float-right mr-2 mt-4 text-white"
                            translate>Cancel</a>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
