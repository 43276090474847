import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ICountry, Timezones } from 'country-state-city/dist/lib/interface';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.services';
import Swal from 'sweetalert2';
import { endpoints } from '../../../core/config/endpoints';
import { AuthService } from '../../../shared/services/auth.service';
import { CountryService } from '../../../shared/services/countrycitystate';

@Component({
  selector: 'app-school',
  templateUrl: './school.component.html',
  styleUrls: ['./school.component.css'],
})
export class SchoolComponent implements OnInit {
  schoolForm: FormGroup | any;
  submitted = false;
  submit = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  settings: any = [];
  packages: any = [];
  countryCodeList: any = [];
  searchText: any;
  page: any;
  pagecounts: any;
  itemPerPage = 50;
  student: any = [];
  listpayment: any = [];
  maxDigit: any;
  minDigit: any;
  timezone: any;
  timezones: Timezones[] = [];
  currentUser: any = [];
  hasBankAccount: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private AuthService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private element: ElementRef,
    private translate: TranslateService,
    private countryService: CountryService
  ) {
    this.countryService.getCountries().forEach((element: ICountry) => {
      if (element.timezones) {
        this.timezones = [...this.timezones, ...element.timezones];
      }
    });
  }

  ngOnInit(): void {
    this.data = this.route.snapshot.data['message'];
    this.settings = this.AuthService.setting;
    this.Apiservice.getApi(endpoints.packagesadminlist.path).subscribe((data: any) => {
      this.packages = data.data.filter((s: any) => s.status == 1);
    });

    if (this.data.auto_renew == '1') {
      this.data.auto_renew = true;
    } else if (this.data.auto_renew == '0') {
      this.data.auto_renew = false;
    }

    this.hasBankAccount = this.data.bank_account;

    this.schoolForm = this.formBuilder.group({
      photo: [null],
      school: [this.data.name, [Validators.required]],
      responsible: [this.data.responsible, [Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
      email: [
        this.data?.email,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,9}$'),
        ]),
      ],
      phone: [this.data.phone, Validators.required],
      phonenumber: [this.data.phonenumber, [Validators.required, Validators.pattern('[- +()0-9]+')]],
      subscriptionenddate: [this.data.subscription_end_date, Validators.required],
      address: [this.data.address, Validators.required],
      countryCode: [this.data.country_code, Validators.required],
      stateCode: [this.data.state_code, Validators.required],
      city: [this.data.city, Validators.required],
      timezone: [this.data.timezone, Validators.required],
      zip_code: [this.data.zip_code, Validators.required],
      package: [this.data.package?.id],
      auto_renew: [this.data.auto_renew],
    });
    this._httpClient.get('assets/countrycode.json').subscribe((data: any) => {
      this.countryCodeList = data.countryCodeList;
    });
    if (this.schoolForm.value.phone == '+1') {
      this.maxDigit = '(000)-000-0000';
      this.minDigit = '(000)-000-0000';
    } else if (this.schoolForm.value.phone == '+55') {
      this.maxDigit = '(00) 00000-0000';
      this.minDigit = '(00) 0000-0000';
    } else {
      this.maxDigit = '(000)-000-0000-00';
      this.minDigit = '(000)-000-00';
    }
  }
  get f() {
    return this.schoolForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.AuthService.schoolname = this.schoolForm.value.school;
    if (this.schoolForm.value.auto_renew == true) {
      this.schoolForm.controls['auto_renew'].setValue(1);
    } else if (this.schoolForm.value.auto_renew == false) {
      this.schoolForm.controls['auto_renew'].setValue(0);
    }
    if (this.schoolForm.invalid) {
      return;
    }

    const formData = this.schoolForm.getRawValue();

    this.Apiservice.postApi(endpoints.updateschool.path + '?id=' + this.AuthService.schoolid, formData).subscribe({
      next: (data: any) => {
        this.showMsg = true;
        this.toaster.success(this.translate.instant('SCHOOL_UPDATED_SUCCESSFULLY'));
      },
      error: (err: any) => {
        this.toaster.error(err.error?.message ?? this.translate.instant('SCHOOL_UPDATE_FAILED'));
        console.log(err);
      },
    });
  }

  Countrychange(event: any) {
    this.schoolForm.controls['countryCode'].setValue(event.countryCode);
    this.schoolForm.controls['stateCode'].setValue(event.stateCode);
    this.schoolForm.controls['city'].setValue(event.city);
    this.schoolForm.controls['zip_code'].setValue(event.zip_code);
  }
  onFileSelected(event: any) {
    let type = event.target?.files[0].type;
    if (type == 'image/png' || type == 'image/jpg' || type == 'image/jpeg') {
      if (event.target.files.length > 0) {
        const file: File = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
          this.loading = false;
          this.element.nativeElement.querySelector('#profilePicPreview').src = reader.result;
          this.schoolForm.patchValue({
            photo: reader.result,
          });
        };
      }
    } else {
      Swal.fire({
        text: this.translate.instant('Image should be acceot only .jpg,.png'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#8d448b',
        cancelButtonColor: '#343a40',
        confirmButtonText: this.translate.instant('Yes'),
        cancelButtonText: this.translate.instant('Cancel'),
      });
      return;
    }
  }
  conutyphone(event: any) {
    this.schoolForm.controls['phone'].setValue(event.code);
    this.maxDigit = event.maxDigit || '(000)-000-0000-00';
    this.minDigit = event.minDigit || '(000)-000-00';
  }

  onImageError(event: any) {
    event.target.src = 'assets/images/group.svg';
  }

  selectTimezone(event: any) {
    this.timezone = event;
    this.schoolForm.controls['timezone'].setValue(this.timezone);
  }

  manageBankAccount() {
    this.loading = true;

    const userDataString = localStorage.getItem('currentUser');

    if (userDataString) {
      this.currentUser = JSON.parse(userDataString);
      const userId = this.currentUser.user_id;

      this.Apiservice.postApi(endpoints.stripeExpressDashboard.path, { user_id: userId }).subscribe(
        (data: any) => {
          //console.log('data', data);
          this.loading = false;
          window.open(data.response.data.express_dashboard_link, '_blank');
        },
        (err: any) => {
          this.loading = false;

          this.toaster.error(err.error.message);
        }
      );
    }
  }

  onToggleAutoRenew(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    //console.log(this.data.id);

    this.Apiservice.postApi(endpoints.autoRenew.path, {
      school_id: this.data.id,
      auto_renew: isChecked,
    }).subscribe(
      (data: any) => {
        //console.log('data', data);
      },
      (err: any) => {
        this.loading = false;

        this.toaster.error(err.error.message);
      }
    );
  }
}
