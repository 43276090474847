<app-header></app-header>
<div id="main-content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xl-12 col-md-12 col-sm-12">
          <h2 translate>Student</h2>
        </div>
        <div class="col-md-12">
          <div class="float-right">
            <a class="btn-sm auth-button mr-2" #closebutton data-toggle="modal"
              data-target="#exampleModal" translate><i
                class="fas fa-file-excel pr-2"></i>Excel</a>
            <a class="btn-sm auth-button"
              [routerLink]="['/admin/student/add-student']" translate><i
                class="fas fa-plus mr-1"></i>New</a>
          </div>
        </div>
      </div>
      <form [formGroup]="rangeForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6 col-12">
            <app-datepicker (OnDate)="OnSelect($event)"></app-datepicker>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-3 col-4">
            <button type="submit" class="btn-sm auth-button submit-newbtn"
              translate>Search</button>
            <button class="btn-sm btn-outline ml-2" type="button"
              (click)="clear()" translate>
              Clear
            </button>
          </div>
        </div>
      </form>
      <div class="row mt-4 table">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="pb-3">
            <ul class="nav nav-pills" id="nav-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link active" id="pills-home-tab"
                  data-toggle="pill" href="#pills-home" role="tab"
                  aria-controls="pills-home" aria-selected="true" translate><i
                    class="fas fa-lock-open"></i>
                  Active</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-profile-tab" data-toggle="pill"
                  href="#pills-profile" role="tab" aria-controls="pills-profile"
                  aria-selected="false" translate><i class="fas fa-lock"></i>
                  In-active</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="tab-content mt-0" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home"
              role="tabpanel" aria-labelledby="pills-home-tab">
              <div class="row clearfix">
                <div class="col-12">
                  <div class="card">
                    <div class="body">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="d-flex">{{ 'Show' | translate}}
                            <select [(ngModel)]="itemPerPage"
                              name="selection-datatable_length"
                              aria-controls="selection-datatable"
                              class="custom-select custom-select-sm form-control form-control-sm w-auto">
                              <option *ngFor="let pageCountObj of pagecounts"
                                [value]="pageCountObj">
                                {{pageCountObj}}</option>
                            </select> {{ 'entries' | translate}}
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="input-group">
                            <input type="text" class="form-control"
                              placeholder="{{'Search'| translate}}"
                              [(ngModel)]="searchText" name="searchText">
                            <div class="input-group-append" *ngIf="searchText">
                              <span class="input-group-text" id="search-mail"
                                (click)="resetsearch()" translate>Clear</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th translate>Name</th>
                            <th translate>Email</th>
                            <th translate>Phone</th>
                            <th translate>Gender</th>
                            <th translate>DOB</th>
                            <th translate>Belt Rank</th>
                            <th translate>Address</th>
                            <th translate class="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let student of studentlist | paginate: { itemsPerPage: itemPerPage, currentPage: page, id:'active' } | filter: searchText; let i = index ">
                            <td>{{student.first_name}} {{student.middle_name}}
                              {{student.last_name}}</td>
                            <td>{{student.email}}</td>
                            <td>{{ student.phone}} {{ student.phonenumber |
                              slice:0:3 }}-{{
                              student.phonenumber | slice:3:6
                              }}-{{ student.phonenumber | slice:6 }}</td>
                            <td class="text-capitalize">{{student.gender}}</td>
                            <td>{{student.dob}}</td>
                            <td *ngIf="student.belt!=null">
                              {{student.belt.color}}</td>
                            <td class="multi_lines_text">{{student.address}}
                            </td>
                            <td>
                              <a class="btn btn-default btn-sm" title="{{ 'View' | translate }}"
                                [routerLink]="['/admin/student/view-student/'+student.id]">
                                <i class="fas fa-eye"></i></a>
                              <a class="btn btn-default btn-sm ml-1"
                                title="{{ 'Edit' | translate }}"
                                [routerLink]="['/admin/student/edit-student/'+student.id]">
                                <i class="fas fa-pencil-alt"></i></a>
                              <a (click)="deleteRow(i, student )"
                                class="btn btn-default btn-sm ml-1"
                                title="{{ 'Delete' | translate }}">
                                <i class="fas fa-trash-alt"></i></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <pagination-controls 
                    id="active" 
                    (pageChange)="pageChange($event)"
                    [previousLabel]="'Previous' | translate"
                    [nextLabel]="'Next' | translate"
                  ></pagination-controls>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel"
              aria-labelledby="pills-profile-tab">
              <div class="row clearfix">
                <div class="col-12">
                  <div class="card">
                    <div class="body">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="d-flex">{{ 'Show' | translate}}
                            <select [(ngModel)]="itemPerPage"
                              name="selection-datatable_length"
                              aria-controls="selection-datatable"
                              class="custom-select custom-select-sm form-control form-control-sm w-auto">
                              <option *ngFor="let pageCountObj of pagecounts"
                                [value]="pageCountObj">
                                {{pageCountObj}}</option>
                            </select> {{ 'entries' | translate}}
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="input-group">
                            <input type="text" class="form-control"
                              placeholder="{{'Search'| translate}}"
                              [(ngModel)]="searchText" name="searchText">
                            <div class="input-group-append" *ngIf="searchText">
                              <span class="input-group-text" id="search-mail"
                                (click)="resetsearch()" translate>Clear</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th translate>Name</th>
                            <th translate>Email</th>
                            <th translate>Phone</th>
                            <th translate>Gender</th>
                            <th translate>DOB</th>
                            <th translate>Belt Rank</th>
                            <th translate>Address</th>
                            <th translate class="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let student of Inactivelist | paginate: { itemsPerPage: itemPerPage, currentPage: pagenext, id:'inactive' } | filter: searchText; let i = index ">
                            <td>{{student.first_name}} {{student.middle_name}}
                              {{student.last_name}}
                            </td>
                            <td>{{student.email}}</td>
                            <td>{{ student.phone}} {{ student.phonenumber |
                              slice:0:3 }}-{{
                              student.phonenumber | slice:3:6
                              }}-{{ student.phonenumber | slice:6 }}</td>
                            <td class="text-capitalize">{{student.gender}}</td>
                            <td>{{student.dob}}</td>
                            <td>{{student.belt.color }}</td>
                            <td class="multi_lines_text">{{student.address}}
                            </td>
                            <td>
                              <a class="btn btn-default btn-sm" title="{{ 'View' | translate }}"
                                [routerLink]="['/admin/student/view-student/'+student.id]">
                                <i class="fas fa-eye"></i></a>
                              <a class="btn btn-default btn-sm ml-1"
                                title="{{ 'Edit' | translate }}"
                                [routerLink]="['/admin/student/edit-student/'+student.id]">
                                <i class="fas fa-pencil-alt"></i></a>
                              <a (click)="deleteActiveRow(i, student )"
                                class="btn btn-default btn-sm ml-1"
                                title="{{ 'Delete' | translate }}">
                                <i class="fas fa-trash-alt"></i></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <pagination-controls 
                    id="inactive" 
                    (pageChange)="pagechange($event)"
                    [previousLabel]="'Previous' | translate"
                    [nextLabel]="'Next' | translate"
                  ></pagination-controls>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row mx-0">
          <div class="col-lg-12">
            <div class="map-card">
              <div class="dotted-box mb-4">
                <form [formGroup]="myForm">
                  <div class="row">
                    <div class="col-lg-6">
                      <input type="file" formControlName="file" #fileInput
                        (change)="fileupload($event)" style="display: none;" />
                      <div class="btn auth-button d-flex" style="width:230px">
                        <button class="btn auth-button p-1"
                          (click)="fileInput.click()" translate>Upload Data From
                          File</button>
                        <div class="dropdown h-100">
                          <button class="btn h-100" type="button"
                            id="dropdownMenuButton" data-toggle="dropdown"
                            aria-expanded="false">
                            <i class="fas fa-angle-down"></i>
                          </button>
                          <div class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" target="_blank"
                              href="https://docs.google.com/spreadsheets/d/1ftoVQVhd5s2Wkz37-AWNSjBZDTy3LUVIdjwf_ugZTyo/edit#gid=0">
                              <i
                                class="fas fa-plus-circle text-primary pr-3 text-white"></i>Example
                              spreadsheet</a>
                          </div>
                        </div>
                      </div>

                      <p translate>.csv, .tsv, .xls, .xlsx, .xml, .txt
                        spreadsheets accepted.</p>
                    </div>
                    <div class="col-lg-6">
                      <p translate>You can upload any .csv, .tsv, .xls, .xlsx,
                        .xml, .txt file with any set
                        ofcolumns
                        as long as it has 1 record per row. The next step will
                        allow you to match your
                        spreadsheet columns to the right data points. You'll be
                        able to clean up or remove
                        any corrupted data before finalizing your report.</p>
                    </div>
                  </div>
                </form>
              </div>
              <p class="text" translate>...or just manually enter your data
                here:</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
