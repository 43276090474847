import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgPaymentCardModule } from 'ng-payment-card';
import { NgChartsModule } from 'ng2-charts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSlickJsModule } from 'ngx-slickjs';
import { CountryService } from 'src/app/shared/services/countrycitystate';
import { SharedModule } from 'src/app/shared/shared.module';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { AddBeltComponent } from './belt/add-belt/add-belt.component';
import { BeltComponent } from './belt/belt.component';
import { AddClassComponent } from './class/add-class/add-class.component';
import { ClassComponent } from './class/class.component';
import { AddCoachComponent } from './coach/add-coach/add-coach.component';
import { CoachComponent } from './coach/coach.component';
import { ViewCoachComponent } from './coach/view-coach/view-coach.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FilterPipe } from './filter.pipe';
import { AddaccountComponent } from './header/addaccount/addaccount.component';
import { HeaderComponent } from './header/header.component';
import { LeftmenuComponent } from './leftmenu/leftmenu.component';
import { AddMessagesComponent } from './messages/add-messages/add-messages.component';
import { MessagesComponent } from './messages/messages.component';
import { NotificationComponent } from './notification/notification.component';
import { AddPackagesComponent } from './packages/add-packages/add-packages.component';
import { PackagesComponent } from './packages/packages.component';
import { AddPaymentComponent } from './payments/add-payment/add-payment.component';
import { PaymentsComponent } from './payments/payments.component';
import { MyPaymentComponent } from './profile/my-payment/my-payment.component';
import { ProfileComponent } from './profile/profile.component';
import { ReportComponent } from './report/report.component';
import { SchoolComponent } from './school/school.component';
import { AddStaffComponent } from './staff/add-staff/add-staff.component';
import { StaffComponent } from './staff/staff.component';
import { AddStudentComponent } from './student/add-student/add-student.component';
import { StudentComponent } from './student/student.component';
import { ViewStudentComponent } from './student/view-student/view-student.component';
import { AddVideoComponent } from './video-category/add-video/add-video.component';
import { EditVideoComponent } from './video-category/edit-video/edit-video.component';
import { VideoCategoryComponent } from './video-category/video-category.component';
import { VideoLibraryComponent } from './video-library/video-library.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgChartsModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatDatepickerModule,
    MatFormFieldModule,
    MatCardModule,
    MatNativeDateModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    NgxDaterangepickerMd.forRoot(),
    NgSelectModule,
    SharedModule,
    PdfViewerModule,
    MatExpansionModule,
    CollapseModule,
    NgxMaskModule.forRoot(),
    NgxSlickJsModule.forRoot({
      links: {
        jquery: 'https://code.jquery.com/jquery-3.4.0.min.js',
        slickJs: 'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js',
        slickCss: 'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css',
        slickThemeCss: 'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css',
      },
    }),
    NgPaymentCardModule,
  ],
  declarations: [
    AdminRoutingModule.components,
    FilterPipe,
    HeaderComponent,
    LeftmenuComponent,
    DashboardComponent,
    StaffComponent,
    AddStaffComponent,
    SchoolComponent,
    ClassComponent,
    AddClassComponent,
    CoachComponent,
    AddCoachComponent,
    ProfileComponent,
    StudentComponent,
    AddStudentComponent,
    ViewStudentComponent,
    PaymentsComponent,
    AddPaymentComponent,
    PackagesComponent,
    AddPackagesComponent,
    MessagesComponent,
    AddMessagesComponent,
    ReportComponent,
    BeltComponent,
    AddBeltComponent,
    ViewCoachComponent,
    NotificationComponent,
    MyPaymentComponent,
    VideoCategoryComponent,
    AddVideoComponent,
    EditVideoComponent,
    VideoLibraryComponent,
    AddaccountComponent,
    AdminComponent,
  ],
  providers: [CountryService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminModule {}
