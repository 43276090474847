<div id="left-sidebar" class="sidebar light_active">
  <button type="button" (click)="cToggoleMenu()" class="btn-toggle-offcanvas btn btn-sm float-right p-3">
    <i class="fas fa-times"></i>
  </button>
  <div class="navbar-brand">
    <a><img src="../../../../assets/img/tatami-logo.png" width="50" alt="" class="img-fluid logo" /> </a>
  </div>
  <div class="user-account profilepointer" [routerLink]="['/superadmin/profile']">
    <div class="user_div">
      <img src="assets/images/user.png" class="user-photo" alt="User Profile Picture" />
    </div>
    <div class="dropdown">
      <span translate>Profile</span>
      <a href="javascript:void(0);" class="user-name"><strong translate>Super Admin</strong></a>
    </div>
  </div>
  <nav id="left-sidebar-nav" class="sidebar-nav">
    <ul id="main-menu" class="metismenu animation-li-delay">
      <li>
        <a [routerLink]="['/superadmin/dashboard']"
          ><i class="fas fa-desktop"></i><span translate>Dashboard</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/superadmin/school']"
          ><i class="fas fa-user"></i><span translate>School Management</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/superadmin/payments']"
          ><i class="fas fa-credit-card"></i><span translate>Payments</span></a
        >
      </li>
      <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <a
          class="settings-li"
          (click)="SalesCollapsed = !SalesCollapsed"
          [attr.aria-expanded]="!SalesCollapsed"
          aria-controls="collapseBasic"
          ><span translate>Settings</span><span class="plusicon" *ngIf="SalesCollapsed">+</span
          ><span class="plusicon" *ngIf="!SalesCollapsed">-</span><i class="fas fa-hand-holding-usd"></i>
        </a>
        <ul class="list-dot" id="collapseBasic" [collapse]="SalesCollapsed" [isAnimated]="true">
          <li>
            <a [routerLink]="['/superadmin/class']">
              <i class="fas fa-user"></i>
              <span translate>Program</span>
            </a>
          </li>
          <!-- <li>
            <a [routerLink]="['/superadmin/seosettings']"
              ><span translate>SEO Settings</span><i class="fas fa-file-invoice"></i
            ></a>
          </li> -->
          <li>
            <a [routerLink]="['/superadmin/positions']"
              ><span translate>Staff Positions</span><i class="fas fa-user-circle"></i
            ></a>
          </li>
          <!-- <li>
            <a [routerLink]="['/superadmin/roles']"
              ><span translate>Roles</span><i class="fas fa-user-cog"></i
            ></a>
          </li> -->
          <li>
            <a [routerLink]="['/superadmin/video-category']"
              ><span translate>Video Category</span><i class="fas fa-video"></i
            ></a>
          </li>
        </ul>
      </li>
      <li>
        <a [routerLink]="['/superadmin/video-library']"
          ><i class="fas fa-video"></i><span translate>Video Library</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/superadmin/packages']"><i class="fas fa-box"></i><span translate>Packages</span></a>
      </li>
      <li>
        <a [routerLink]="['/superadmin/messages']"
          ><i class="fas fa-envelope"></i><span translate>Messages</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/superadmin/notification']"
          ><i class="fas fa-bell"></i><span translate>Notification</span></a
        >
      </li>
      <!-- <li>
                <a [routerLink]="['/superadmin/report']"><i class="fas fa-file"></i><span translate>Report</span></a>
            </li> -->
    </ul>
  </nav>
</div>
