import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from 'src/app/shared/services/api.services';
import { endpoints } from '../../../../core/config/endpoints';
import { AuthService } from '../../../../shared/services/auth.service';

import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-student',
  templateUrl: './add-student.component.html',
  styleUrls: ['./add-student.component.css'],
})
export class AddStudentComponent implements OnInit {
  studentForm: FormGroup | any;
  submitted = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  userid: any;
  routerid: any;
  classData: any = [];
  belt: any = [];
  assignclass: any = [];
  countryCodeList: any = [];
  maxDigit: any;
  minDigit: any;

  dropdownSettings: any = {};
  dropdownlist: any = [];

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private AuthService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private element: ElementRef,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.Apiservice.getApi(endpoints.beltlist.path).subscribe((data: any) => {
      this.belt = data.data.filter((s: any) => s.status == 1);
    });

    this.userid = this.route.params;
    this.routerid = this.userid._value.id;
    this.Apiservice.getApi(endpoints.assignclass.path).subscribe((data: any) => {
      this.assignclass = data.data;
    });

    this.data = this.route.snapshot.data['message'];
    if (!this.routerid) {
      this.studentForm = this.formBuilder.group({
        photo: [''],
        file: [''],
        first_name: ['', [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        middle_name: ['', [Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        last_name: ['', [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,9}$'),
          ]),
        ],
        phone: ['+55', Validators.required],
        phonenumber: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
        DOB: ['', Validators.required],
        belt: [null, Validators.required],
        gender: [null, Validators.required],
        countryCode: ['', Validators.required],
        stateCode: ['', Validators.required],
        city: ['', Validators.required],
        zip_code: ['', Validators.required],
        address: ['', Validators.required],
      });
    } else {
      this.studentForm = this.formBuilder.group({
        photo: [''],
        file: [this.data.file],
        first_name: [this.data.first_name, [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        middle_name: ['', [Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        last_name: [this.data.last_name, [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        email: [this.data.email, Validators.required],
        phone: [this.data.phone, Validators.required],
        phonenumber: [this.data.phonenumber, [Validators.required]],
        DOB: [this.data.dob, Validators.required],
        belt: [this.data.belt.id, Validators.required],
        gender: [this.data.gender, Validators.required],
        address: [this.data.address, Validators.required],
        countryCode: [this.data.country_code, Validators.required],
        stateCode: [this.data.state_code, Validators.required],
        city: [this.data.city, Validators.required],
        zip_code: [this.data.zip_code, Validators.required],
      });
      if (this.data.middle_name != null) {
        this.studentForm.controls['middle_name'].setValue(this.data.middle_name);
      }
    }

    this._httpClient.get('assets/countrycode.json').subscribe((data: any) => {
      this.countryCodeList = data.countryCodeList;
    });
    if (this.studentForm.value.phone == '+1') {
      this.maxDigit = '(000)-000-0000';
      this.minDigit = '(000)-000-0000';
    } else if (this.studentForm.value.phone == '+55') {
      this.maxDigit = '(00) 00000-0000';
      this.minDigit = '(00) 0000-0000';
    } else {
      this.maxDigit = '(000)-000-0000-00';
      this.minDigit = '(000)-000-00';
    }
  }
  get f() {
    return this.studentForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.studentForm.invalid) {
      return;
    }
    let studentemail = this.studentForm.value.email.toLowerCase();
    this.studentForm.controls['email'].setValue(studentemail);
    const formData = new FormData();
    formData.append('photo', this.studentForm.get('photo').value);
    formData.append('file', this.studentForm.get('file').value);
    let formfile = [
      'first_name',
      'middle_name',
      'last_name',
      'email',
      'phone',
      'phonenumber',
      'DOB',
      'belt',
      'address',
      'countryCode',
      'stateCode',
      'city',
      'zip_code',
      'gender',
    ];
    formfile.forEach((name: string) => {
      formData.append(name, this.studentForm.get(name).value);
    });

    if (!this.routerid) {
      this.Apiservice.postApi(endpoints.addstudent.path, formData).subscribe(
        (data: any) => {
          this.toaster.success(this.translate.instant('Student Added Successfully'));
          this.router.navigate(['/admin/payment/add-payment/' + data.student.id]);
        },
        (err: any) => {
          if (err.error.errors) {
            this.toaster.error(err.error.errors.email || err.error.errors.phonenumber);
          } else {
            this.toaster.error(err.error.message);
          }
        }
      );
    }
    if (this.routerid) {
      this.Apiservice.postApi(endpoints.updatestudent.path + '?id=' + this.routerid, formData).subscribe(
        (data: any) => {
          this.showMsg = true;
          this.toaster.success(this.translate.instant('Student updated Successfully'));
          this.router.navigate(['/admin/student']);
        },
        (err: any) => {
          if (err.error.errors) {
            this.toaster.error(err.error.errors.email || err.error.errors.phonenumber);
          } else {
            this.toaster.error(err.error.message);
          }
        }
      );
    }
  }

  Countrychange(event: any) {
    this.studentForm.controls['countryCode'].setValue(event.countryCode);
    this.studentForm.controls['stateCode'].setValue(event.stateCode);
    this.studentForm.controls['city'].setValue(event.city);
    this.studentForm.controls['zip_code'].setValue(event.zip_code);
  }
  onFileSelected(event: any) {
    let type = event.target.files[0].type;
    if (type == 'image/png' || type == 'image/jpg' || type == 'image/jpeg') {
      const file = <File>event.target.files[0];
      this.studentForm.patchValue({
        photo: file,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        this.loading = false;
        this.element.nativeElement.querySelector('#profilePicPreview').src = reader.result;
      };
    } else {
      Swal.fire({
        text: 'Image should be acceot only .jpg,.png',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#8d448b',
        cancelButtonColor: '#343a40',
        confirmButtonText: 'Yes',
      });
      return;
    }
  }
  onSelected(event: any) {
    let type = event.target.files[0].type;

    if (type == 'application/pdf') {
      const file = <File>event.target.files[0];
      this.studentForm.patchValue({
        file: file,
      });
    } else {
      Swal.fire({
        text: this.translate.instant('Image should be acceot only .jpg,.png'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#8d448b',
        cancelButtonColor: '#343a40',
        confirmButtonText: this.translate.instant('Yes'),
        cancelButtonText: this.translate.instant('Cancel'),
      });
      return;
    }
  }
  conutyphone(event: any) {
    this.studentForm.controls['phone'].setValue(event.code);
    this.maxDigit = event.maxDigit || '(000)-000-0000-00';
    this.minDigit = event.minDigit || '(000)-000-00';
  }
}
