<main class="e-single-event-page">
  <section id="main-content">
    <!-- Header -->
    <header class="e-single-event-page_header">
      <h2 class="e-single-event-page_header__title">{{ headerTitle | translate }}</h2>
      <app-button variant="secondary" (click)="goToEventList()"> {{ 'All Classes' | translate }} </app-button>
    </header>

    <app-summary-card
      class="e-single-event-page__summary"
      *ngIf="activeStep === 2"
      [details]="summaryDetails"></app-summary-card>

    <!-- Content -->
    <form [formGroup]="form" class="e-single-event-page_content">
      <div class="e-single-event-page_slider">
        <div class="e-single-event-page_slider__item" #eventForm [hidden]="activeStep !== 1">
          <app-event-form groupName="event"></app-event-form>
        </div>
        <div class="e-single-event-page_slider__item" #scheduleForm [hidden]="activeStep !== 2">
          <app-schedule-form
            groupName="schedule"
            [shouldLoadSchedule]="isEdit && !loadingPage"
            [summaryDetails]="summaryDetails"></app-schedule-form>
        </div>
      </div>
      <div class="e-single-event-page__divisor"></div>
      <div class="e-single-event-page_stepper">
        <div
          class="e-single-event-page_stepper__dot"
          [class.e-single-event-page_stepper__dot--active]="activeStep === 1"></div>
        <div
          class="e-single-event-page_stepper__dot"
          [class.e-single-event-page_stepper__dot--active]="activeStep === 2"></div>
      </div>
      <div class="e-single-event-page_footer">
        <app-button *ngIf="activeStep === 1" class="u-flex--full" variant="secondary" (click)="goToEventList()">
          {{ 'Cancel' | translate }}
        </app-button>
        <app-button
          *ngIf="activeStep === 2"
          class="u-flex--full"
          variant="secondary"
          icon="arrow-back"
          [hideIconOnMobile]="true"
          (click)="slideTo(1)">
          {{ 'PREVIOUS' | translate }}
        </app-button>
        <app-button
          *ngIf="activeStep === 1"
          class="u-flex--full"
          variant="primary"
          icon="arrow-forward"
          [iconAfterLabel]="true"
          [hideIconOnMobile]="true"
          [disabled]="!canProceed"
          (click)="slideTo(2)">
          {{ 'NEXT' | translate }}
        </app-button>
        <app-button
          *ngIf="activeStep === 2"
          class="u-flex--full"
          variant="primary"
          icon="checkmark"
          [iconAfterLabel]="true"
          [hideIconOnMobile]="true"
          [loading]="isLoading"
          [disabled]="!canProceed || form.invalid"
          (click)="submitClass()">
          {{ isEdit ? ['UPDATE_CLASS' | translate] : ['CREATE_CLASS' | translate] }}
        </app-button>
      </div>
    </form>
  </section>
</main>
