import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from '../admin.component';
import { EventsPage } from './pages/events/events.page';
import { SingleEventPage } from './pages/single-event/single-event.page';

const routes: Routes = [
  {
    path: 'admin/events',
    component: AdminComponent,
    children: [
      {
        path: '',
        component: EventsPage,
      },
      {
        path: 'create',
        component: SingleEventPage,
      },
      {
        path: 'edit/:id',
        component: SingleEventPage,
        data: {
          isEdit: false,
          scheduleOnly: false,
        },
      },
      { path: '**', redirectTo: '', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EventsRoutingModule {}
