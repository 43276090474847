import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import {
  ClassAvailability,
  ClassEntity,
  ClassScheduleMap,
  Schedules,
  WeekDaysEnum,
} from 'src/app/modules/admin/events/state/events.interface';
import { ClassCardAction, ClassCardActionOutput } from '../../interfaces/class-card-action.interface';
import { ClassesService } from '../../services/classes.service';

@Component({
  selector: 'app-class-card',
  templateUrl: './class-card.component.html',
  styleUrls: ['./class-card.component.scss'],
  animations: [
    trigger('showHide', [
      transition(':enter', [style({ opacity: 0 }), animate('150ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('150ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ClassCardComponent {
  @Input() class: ClassEntity | undefined;

  @Input() availability: ClassAvailability = 'open';

  @Output() actionClicked = new EventEmitter<ClassCardActionOutput>();

  status: string | undefined;

  showMenu: boolean = false;

  showHiddenDates: boolean = false;

  allDates: ClassScheduleMap[] = [];

  hiddenDates: ClassScheduleMap[] = [];

  visibleDates: ClassScheduleMap[] = [];

  hiddenDatesQuantity: number = 0;

  isMobile: boolean = false;

  scheduleItems = new Map<string, ClassScheduleMap>();

  locale: string;

  maxMobileSize = 576;

  constructor(
    private translate: TranslateService,
    private classesService: ClassesService
  ) {
    this.isMobile = window.innerWidth < this.maxMobileSize;
    this.locale = localStorage.getItem('language') || 'pt-br';
  }

  ngOnInit() {
    if (!this.class) return;

    this.status = this.classesService.handleClassStatus(this.class);
    this.handleDisplayDates();
    this.handleVisibleDates();
    this.isClassClosed();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < this.maxMobileSize;
  }

  isClassClosed(): boolean {
    return this.class?.availability === 'closed';
  }

  isClassOpen(): boolean {
    return this.class?.availability === 'open';
  }

  handleStatusMessage(): string {
    let currentLang = 'pt-BR';
    if (this.locale === 'es') {
      currentLang = 'es-ES';
    } else if (this.locale === 'en') {
      currentLang = 'en-US';
    }
    const endDate = moment(this.class?.end_date)
      .locale(currentLang)
      .format('MMM Do, YYYY');
    const startDate = moment(this.class?.start_date)
      .locale(currentLang)
      .format('MMM Do, YYYY');
    const manuallyClosedDate = moment(this.class?.manually_closed_at)
      .locale(currentLang)
      .format('MMM Do, YYYY');

    let message = '';
    if (this.status === 'closed_manually') {
      message = this.translate.instant('EVENTS_PAGE.CLOSED_MANUALLY_ON');
      return message + ' ' + manuallyClosedDate;
    } else if (this.status === 'closed_automatically') {
      message = this.translate.instant('EVENTS_PAGE.CLOSED_AUTOMATICALLY_ON');
      return message + ' ' + endDate;
    } else if (this.status === 'open_with_expiration') {
      message = this.translate.instant('EVENTS_PAGE.CLOSES_AUTOMATICALLY_ON');
      return message + ' ' + endDate;
    } else {
      // upcoming
      message = this.translate.instant('EVENTS_PAGE.STARTING_ON');
      return message + ' ' + startDate;
    }
  }

  getFormatedDays(days: number[]): string {
    if (days.length === 7) {
      return this.translate.instant('EVENTS_PAGE.EVERY_DAY');
    }

    let formattedDays = days.map((day) => {
      const dayAcronym = WeekDaysEnum[day].substring(0, 3).toUpperCase();
      return this.translate.instant('EVENTS_PAGE.' + dayAcronym);
    });
    return formattedDays.join(', ');
  }

  formatTime(timeString: string) {
    const momentTime = moment(timeString, ['HH:mm:ss']);
    return this.locale !== 'pt-br' ? momentTime.format('h:mmA') : momentTime.format('HH:mm');
  }

  toggleDatesList(toggleValue: boolean = false, event: Event) {
    if ((event.type === 'mouseover' || event.type === 'mouseleave') && this.isMobile) return;
    if (event.type === 'click' && !this.isMobile) return;
    this.showHiddenDates = toggleValue;
  }

  onActionClick(action: ClassCardAction) {
    this.showMenu = false;
    this.actionClicked.emit({
      classId: this.class?.class_id || '',
      action,
    });
  }

  closeMenu() {
    this.showMenu = false;
  }

  private handleVisibleDates() {
    if (!this.class) return;

    this.allDates = Array.from(this.scheduleItems.values());
    const quantity = this.allDates.length ? 2 : 0;
    if (quantity) {
      this.hiddenDatesQuantity = this.allDates.length - quantity;
      this.visibleDates = this.allDates.slice(0, quantity) ?? [];
      this.hiddenDates = this.allDates.slice(quantity) ?? [];
    }
  }

  private handleDisplayDates() {
    if (!this.class) return;

    this.class.schedules.forEach((item: Schedules) => {
      const key = item.start_time + '_' + item.end_time;
      if (this.scheduleItems.has(key)) {
        const keyDays = this.scheduleItems.get(key)?.days || [];
        const newKeyDays = [...keyDays, item.day_value];

        this.scheduleItems.set(key, {
          start_time: item.start_time,
          end_time: item.end_time,
          days: newKeyDays,
        });
      } else {
        this.scheduleItems.set(key, {
          start_time: item.start_time,
          end_time: item.end_time,
          days: [item.day_value],
        });
      }
    });
  }
}
