<div id="main-contents" class="pt-4">
  <div class="container-fluid">
    <div class="">
      <a class="btn" [routerLink]="['/auth/login']"><i class="fas fa-arrow-left mr-1"></i></a>
    </div>
    <div class="row clearfix">
      <div class="col-lg-4 e-register-side-image">
        <div class="e-register-side-image__image"></div>
      </div>
      <div class="col-lg-8 col-md-12 col-sm-12 p-5 auth-form mb-4">
        <div class="e-logo-container">
          <img src="/assets/img/logo_text-horizontal-fundoazul.png" alt="Tatami" />
        </div>
        <div class="row">
          <div class="col-lg-12">
            <form *ngIf="registerForm" [formGroup]="registerForm">
              <div class="e-schedule-form-line">
                <div class="e-schedule-form_input-container">
                  <div class="e-schedule-form_input-container__title">
                    <span>{{ 'REGISTER.TITLE' | translate }}</span>
                  </div>
                  <div class="e-schedule-form_input-container__subtitle">
                    <span>{{ 'REGISTER.SUBTITLE' | translate }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                  <app-field-input
                    formControlName="name"
                    type="text"
                    maxlength="150"
                    class="mt-3"
                    [isRequired]="true"
                    [clearButton]="true"
                    [label]="'REGISTER.NAME_LABEL' | translate"
                    [placeholder]="'REGISTER.NAME_PLACEHOLDER' | translate"></app-field-input>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                  <app-field-input
                    formControlName="email"
                    type="email"
                    maxlength="64"
                    class="mt-3"
                    [isRequired]="true"
                    [clearButton]="true"
                    [label]="'Email' | translate"
                    [placeholder]="'Email' | translate"></app-field-input>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                  <div class="c-field">
                    <label class="c-field__label"> {{ 'Phone Number' | translate }} <span>*</span> </label>
                  </div>

                  <div class="input-group">
                    <div class="input-group-prepend e-form-phone">
                      <ng-select
                        formControlName="phone_code"
                        class="e-form-phone e-form-phone__code"
                        (change)="conutyphone($event)"
                        [ngClass]="{ 'is-invalid': submitted && fff.phone_code.errors }">
                        <ng-option *ngFor="let status of countryCodeList" [value]="status">
                          {{ status.code }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <input
                      type="text"
                      class="form-control e-form-phone"
                      [placeholder]="minDigit"
                      mask=" {{ minDigit }} || {{ maxDigit }}"
                      [validation]="false"
                      formControlName="phone_number"
                      [ngClass]="{ 'is-invalid': submitted && fff.phone_number.errors }" />
                    <div *ngIf="submitted && fff.phone_number.errors" class="invalid-feedback">
                      <div *ngIf="fff.phone_number.errors.required" translate>Phone Number is required</div>
                      <!-- <div *ngIf="f.phonenumber.errors.minlength">Mobile should be 10 character long
                            </div>
                            <div *ngIf="f.phonenumber.errors.pattern">Only Numbers are allowed</div> -->
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                  <app-field-input
                    formControlName="school_name"
                    type="text"
                    maxlength="150"
                    [isRequired]="true"
                    [clearButton]="true"
                    [label]="'REGISTER.SCHOOL_NAME_LABEL' | translate"
                    [placeholder]="'REGISTER.SCHOOL_NAME_LABEL' | translate"></app-field-input>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                  <app-field-input
                    formControlName="password"
                    type="password"
                    maxlength="150"
                    [isRequired]="true"
                    [clearButton]="true"
                    [label]="'REGISTER.PASSWORD_LABEL' | translate"
                    [placeholder]="'REGISTER.PASSWORD_PLACEHOLDER' | translate"></app-field-input>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                  <app-field-input
                    formControlName="password_confirmation"
                    type="password"
                    maxlength="150"
                    [isRequired]="true"
                    [clearButton]="true"
                    [label]="'REGISTER.PASSWORD_CONFIRMATION_LABEL' | translate"
                    [placeholder]="'REGISTER.PASSWORD_CONFIRMATION_PLACEHOLDER' | translate"></app-field-input>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6 col-12 mt-5">
                  <app-button
                    icon="checkmark"
                    [iconAfterLabel]="true"
                    variant="primary"
                    [isSubmit]="true"
                    [loading]="submitting"
                    [disabled]="registerForm.invalid"
                    (click)="onSubmit()"
                    >{{ 'REGISTER.SUBMIT_BUTTON' | translate }}</app-button
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="e-register-background"></div>
