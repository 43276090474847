import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription, finalize } from 'rxjs';
import { ClassesFilters } from 'src/app/shared/components/search-class/search-class.interfaces';
import { ClassAvailability, ClassEntity, EventsFilter, ProgramEntity } from '../../state/events.interface';
import { EventsRepository } from '../../state/events.repository';
import { EventsService } from '../../state/events.service';
import { HeaderService } from './../../../header/state/header.service';

type ClassesType = {
  open: ClassEntity[];
  upcoming: ClassEntity[];
  closed: ClassEntity[];
};
@Component({
  selector: 'app-events',
  templateUrl: './events.page.html',
  styleUrls: ['./events.page.scss'],
})
export class EventsPage {
  headerTitle: string = '';

  availability: ClassAvailability = 'open';

  hasSearch = false;

  filters: EventsFilter = {
    coach_id: '',
    name: '',
    startDate: '', //date
    endDate: '', //date
    program_id: '',
  };

  classes: ClassesType | undefined;

  filteredClasses: ClassesType | undefined;

  isMobile: boolean = false;

  showSearchFields: boolean = false;

  isLoading: boolean = false;

  /** list programs registered ob the db */
  programList: ProgramEntity[] = [];

  classesSub$: Subscription | undefined;
  statusIdSub$: Subscription | undefined;
  filterSub$: Subscription | undefined;

  constructor(
    private eventsService: EventsService,
    private eventsRepo: EventsRepository,
    private router: Router,
    private toaster: ToastrService,
    private headerService: HeaderService
  ) {
    this.isMobile = window.innerWidth < 768;

    // get classes from store and filter by availability
    this.classesSub$ = this.eventsRepo.allClasses$.subscribe((classes) => {
      this.classes = {
        open: classes.filter((item) => item.availability === 'open') || [],
        upcoming: classes.filter((item) => item.availability === 'upcoming') || [],
        closed: classes.filter((item) => item.availability === 'closed') || [],
      };
      // init filter property
      if (this.classes) this.filteredClasses = { ...this.classes };
    });
  }

  ngOnInit() {
    this.headerService.pageTitle$.next('All Classes');
    this.headerService.displayGoBack$.next(false);

    this.headerTitle = this.isMobile ? 'EVENTS_PAGE.HEADER_TITLE_SEARCH' : 'EVENTS_PAGE.HEADER_TITLE';
    this.eventsRepo.resetFilter();
    this.fetchClasses();
    this.fetchCoaches();
    this.fetchAdditionalInfo();
  }

  fetchClasses() {
    if (this.classes?.open.length) {
      this.isLoading = false;
    } else {
      this.isLoading = true;
    }

    this.eventsService
      .getClasses(this.filters)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        // TODO: create top-page a loader to update the classes
        error: (err) => console.log(err),
      });
  }

  fetchCoaches() {
    this.eventsService.getCoaches().subscribe({
      // TODO: create top-page a loader to update the classes
      error: (err) => console.log(err),
    });
  }

  fetchAdditionalInfo() {
    this.eventsService.getPrograms().subscribe({
      next: (programs) => {
        this.programList = programs;
      },
      error: (err) => console.log(err),
    });

    this.filterSub$ = this.eventsRepo.filters$.subscribe((filters) => {
      this.onFilterUpdate(filters);
    });
  }

  handleChipSelected(value: ClassAvailability | string | null): void {
    this.availability = (value as ClassAvailability) || 'open';
  }

  handleFilterHeaderAction(preventClick = false) {
    if (preventClick) return;

    this.showSearchFields = !this.showSearchFields;
  }

  onFilterUpdate(filters: ClassesFilters) {
    // clear filter
    if (!filters?.name && !filters?.program && !filters?.coach && !filters.startDate) {
      this.filteredClasses = this.classes;
      this.hasSearch = false;
      return;
    }

    // add filter
    this.classesSub$ = this.eventsRepo.allClasses$.subscribe((classes) => {
      this.hasSearch = true;
      let filteredItems = classes;
      if (filters?.program) {
        filteredItems = filteredItems.filter((item) => item.program.program_id === filters.program);
      }
      if (filters?.coach) {
        filteredItems = filteredItems.filter((item) => item.coach.user_id === filters.coach);
      }
      if (filters?.startDate && filters?.endDate) {
        filteredItems = filteredItems.filter(
          (item) =>
            moment(filters.startDate).isSameOrAfter(item.start_date) &&
            moment(filters.endDate).isSameOrBefore(item.end_date)
        );
      }
      if (filters?.name) {
        filteredItems = filteredItems.filter((item) =>
          item.name.toLowerCase().includes(filters.name!.toLowerCase())
        );
      }
      this.filteredClasses = {
        open: filteredItems.filter((item) => item.availability === 'open') || [],
        upcoming: filteredItems.filter((item) => item.availability === 'upcoming') || [],
        closed: filteredItems.filter((item) => item.availability === 'closed') || [],
      };
    });
  }

  ngOnDestroy() {
    this.classesSub$?.unsubscribe();
    this.statusIdSub$?.unsubscribe();
    this.filterSub$?.unsubscribe();
  }

  removeClassTimeInput() {
    console.log('Remove class time input.');
  }

  goToCreatePage() {
    this.router.navigate(['/admin/events/create']);
  }
}
