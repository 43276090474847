import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from 'src/app/shared/services/api.services';
import { endpoints } from '../../../../core/config/endpoints';
import { AuthService } from '../../../../shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-belt',
  templateUrl: './add-belt.component.html',
  styleUrls: ['./add-belt.component.css'],
})
export class AddBeltComponent implements OnInit {
  packageform: FormGroup | any;
  submitted = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  beltid: any;
  routerid: any;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private AuthService: AuthService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.beltid = this.route.params;
    this.routerid = this.beltid._value.id;
    this.data = this.route.snapshot.data?.message?.data;

    if (this.routerid && this.data) {
      this.packageform = this.formBuilder.group({
        name: [this.data.name, [Validators.required, Validators.pattern("^[a-zA-Z -']+")]],
        color: [this.data.color, [Validators.required, Validators.pattern("^[a-zA-Z -']+")]],
        range: [this.data.range, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        time_line: [this.data.time_line],
      });
    } else {
      this.packageform = this.formBuilder.group({
        name: ['', [Validators.required, Validators.pattern("^[a-zA-Z -']+")]],
        color: ['', [Validators.required, Validators.pattern("^[a-zA-Z -']+")]],
        range: ['', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        time_line: [null],
      });
    }
  }
  get f() {
    return this.packageform.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.packageform.invalid) {
      return;
    }
    if (!this.routerid) {
      this.Apiservice.postApi(endpoints.addbelt.path, this.packageform.value).subscribe(
        (data: any) => {
          this.showMsg = true;
          this.toaster.success(this.translate.instant('Belt added Successfully'));
          this.router.navigate(['/admin/belt']);
        },
        (err: any) => {
          this.toaster.error(err.error.message || err.error);
        }
      );
    } else {
      this.Apiservice.postApi(
        endpoints.updatebelt.path + '?id=' + this.routerid,
        this.packageform.value
      ).subscribe(
        (data: any) => {
          this.showMsg = true;
          this.toaster.success(this.translate.instant('Belt updated successfully'));
          this.router.navigate(['/admin/belt']);
        },
        (err: any) => {
          this.toaster.error(err.error.message || err.error);
        }
      );
    }
  }
}
