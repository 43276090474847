<div id="left-sidebar" class="sidebar light_active">
  <button type="button" (click)="cToggoleMenu()" class="btn-toggle-offcanvas btn btn-sm float-right p-3">
    <i class="fas fa-times"></i>
  </button>
  <div class="navbar-brand">
    <a><img src="../../../../assets/img/tatami-logo.png" width="50" alt="" class="img-fluid logo" /> </a>
  </div>
  <div class="user-account profilepointer" [routerLink]="['/student/profile']">
    <div class="user_div">
      <img src="assets/images/user.png" class="user-photo" alt="User Profile Picture" />
    </div>
    <div class="dropdown">
      <span translate>Profile</span>
      <a href="javascript:void(0);" class="user-name"><strong translate>Student</strong></a>
    </div>
  </div>
  <nav id="left-sidebar-nav" class="sidebar-nav">
    <ul id="main-menu" class="metismenu animation-li-delay">
      <li>
        <a [routerLink]="['/student/dashboard']"><i class="fas fa-desktop"></i><span translate>Dashboard</span></a>
      </li>
      <li>
        <a [routerLink]="['/student/schedule']"><i class="fas fa-calendar"></i><span translate>Class</span></a>
      </li>
      <li>
        <a [routerLink]="['/student/video-library']"
          ><i class="fas fa-video"></i><span translate>Video Library</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/student/attendance']"
          ><i class="fas fa-digital-tachograph"></i><span translate>Attendance</span></a
        >
      </li>
      <!-- <li>
                <a [routerLink]="['/student/belt']"><i class="fas fa-fill"></i><span>Belt</span></a>
            </li> -->

      <li>
        <a [routerLink]="['/student/payment']"
          ><i class="fas fa-credit-card"></i><span translate>Payments</span></a
        >
      </li>

      <li>
        <a [routerLink]="['/student/messages']"
          ><i class="fas fa-comment-alt"></i><span translate>Message</span></a
        >
      </li>

      <li>
        <a [routerLink]="['/student/notification']"
          ><i class="fas fa-bell"></i><span translate>Notification</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/student/settings']"><i class="fas fa-cog"></i><span translate>Settings</span></a>
      </li>
      <!-- <li>
                <a [routerLink]="['/student/report']"><i class="fas fa-file"></i><span translate>Report</span></a>
            </li> -->
    </ul>
  </nav>
</div>
