import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { ConfirmationModal } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { NotesComponent } from 'src/app/shared/components/notes/notes.component';
import { ClassCardActionOutput } from '../../../../../shared/interfaces/class-card-action.interface';
import { ClassAvailability, ClassEntity, ProgramEntity } from '../../state/events.interface';
import { EventsService } from '../../state/events.service';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss'],
})
export class EventsListComponent {
  @Input() classes: ClassEntity[] | undefined = [];

  @Input() programs: ProgramEntity[] = [];

  @Input() hasFilters: boolean = false;

  @Input() status: ClassAvailability = 'open';

  @Output() classUpdated = new EventEmitter<void>();

  activeClass: ClassEntity | undefined = undefined;

  currentAction: 'open' | 'close' = 'open';

  blockActions: boolean = false;

  constructor(
    private router: Router,
    private eventsService: EventsService,
    private toaster: ToastrService,
    private translate: TranslateService,
    public modalService: NgbModal
  ) {}

  hasClassesFromProgram(program: ProgramEntity) {
    return this.classes?.some((el) => el.program.program_id === program.program_id);
  }

  performAction(output: ClassCardActionOutput) {
    switch (output.action) {
      case 'add-note':
        this.addNote(output.classId);
        break;
      case 'edit-class':
        this.editClass(output.classId);
        break;
      case 'close-class':
        this.openCloseClass(output.classId, 'close');
        break;
      case 'open-class':
        this.openCloseClass(output.classId, 'open');
        break;
    }
  }

  addNote(classId: string) {
    const modalRef = this.modalService.open(NotesComponent, {
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.classId = classId;
  }

  editClass(classId: string) {
    this.router.navigate(['/admin/events/edit', classId]);
  }

  openCloseClass(classId: string, action: 'open' | 'close') {
    this.activeClass = this.classes?.find((el) => el.class_id === classId);
    this.currentAction = action;

    const modalRef = this.modalService.open(ConfirmationModal, {
      centered: true,
      modalDialogClass: 'c-modal_custom',
    });
    modalRef.componentInstance.class = this.activeClass;
    modalRef.componentInstance.action = this.currentAction;

    modalRef.componentInstance.clickevent.subscribe((action: string) => {
      if (action === 'confirm') {
        this.onConfirm();
        this.blockActions = true;
      }
    });
  }

  onConfirm() {
    if (!this.activeClass || this.blockActions) return;

    this.eventsService
      .patchClassStatus(this.activeClass.class_id, this.currentAction)
      .pipe(finalize(() => (this.blockActions = false)))
      .subscribe({
        next: () => {
          this.toaster.success(
            this.translate.instant(
              this.currentAction === 'open' ? 'CLASS_OPENED_SUCCESSFULLY' : 'CLASS_CLOSED_SUCCESSFULLY'
            )
          );
          this.classUpdated.emit();
        },
        error: () => {
          this.toaster.error(
            this.translate.instant(
              this.currentAction === 'open' ? 'FAILED_TO_OPEN_CLASS' : 'FAILED_TO_CLOSE_CLASS'
            )
          );
        },
      });
  }
}
