import { Injectable } from '@angular/core';
import { ClassEntity } from '../../modules/admin/events/state/events.interface';

@Injectable()
export class ClassesService {
  public handleClassStatus(classEntity: ClassEntity): string {
    switch (classEntity.availability) {
      case 'closed':
        return classEntity.manually_closed_at ? 'closed_manually' : 'closed_automatically';
      case 'open':
        return classEntity.end_date ? 'open_with_expiration' : 'open';

      default:
        return 'upcoming';
    }
  }
}
