import { Component, EventEmitter, HostListener, Inject, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-leftmenu',
  templateUrl: './leftmenu.component.html',
  styleUrls: ['./leftmenu.component.scss'],
})
export class LeftmenuComponent implements OnInit {
  @Output() onLogout = new EventEmitter<void>();
  @Output() onLanguageChanged = new EventEmitter<string>();

  photo: any;
  SalesCollapsed = true;
  isMobile: boolean = false;
  maxMobileSize: number = 576;

  constructor(
    private router: Router,
    @Inject(AppComponent) private app: AppComponent,
    private authservice: AuthService
  ) {
    if (this.router.url.includes('staff')) {
      this.SalesCollapsed = false;
    }
    if (this.router.url.includes('class')) {
      this.SalesCollapsed = false;
    }
    if (this.router.url.includes('video-category')) {
      this.SalesCollapsed = false;
    }
    this.isMobile = window.innerWidth < this.maxMobileSize;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < this.maxMobileSize;
  }

  ngOnInit(): void {
    this.photo = this.authservice.photo;
  }

  logOut() {
    this.onLogout.emit();
  }

  toggleMenu() {
    const body = document.getElementsByTagName('body')[0];

    if (body.classList.contains('toggle_menu_active')) {
      body.classList.remove('toggle_menu_active');
    } else {
      body.classList.add('toggle_menu_active');
    }
  }

  cToggoleMenu() {
    const body = document.getElementsByTagName('body')[0].classList.remove('offcanvas-active');
    //document.getElementsByClassName('overlay')[0].classList.toggle("open");
  }

  useLanguage(language: string) {
    this.onLanguageChanged.emit(language);
  }
}
