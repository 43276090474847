import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from '../../../../shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from '../../../../core/config/endpoints';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-class',
  templateUrl: './add-class.component.html',
  styleUrls: ['./add-class.component.css'],
})
export class AddClassComponent implements OnInit {
  classForm: FormGroup | any;
  submitted = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  userid: any;
  routerid: any;
  settings: any = [];
  position: any = [];

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private AuthService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.settings = this.AuthService.setting;
    this.position = this.settings.data.filter(
      (t: any) => t.group == 'staffs-position'
    );

    this.userid = this.route.params;
    this.routerid = this.userid._value.id;

    if (!this.routerid) {
      this.classForm = this.formBuilder.group({
        name: ['', Validators.required],
        assign_status:[0]
      });
    } else {
      this.data = this.route.snapshot.data['message'];
      this.classForm = this.formBuilder.group({
        name: [this.data.name, Validators.required],
        assign_status:['']
      });
      if(this.data.assign_status=='0'){
        this.classForm.controls['assign_status'].setValue(false)
      }else if(this.data.assign_status=='1'){
        this.classForm.controls['assign_status'].setValue(true)
      }
      
    }
  }
  get f() {
    return this.classForm.controls;
  }
  
  onSubmit() {
    this.submitted = true;
    if (this.classForm.invalid) {
      return;
    }
    if(this.classForm.value.assign_status==true){
      this.classForm.controls['assign_status'].setValue(1)
    }else if(this.classForm.value.assign_status==false){
      this.classForm.controls['assign_status'].setValue(0)
    }
    if (!this.routerid) {
      this.Apiservice.postApi(
        endpoints.addclass.path,
        this.classForm.value
      ).subscribe(
        (data: any) => {
          this.showMsg = true;
          this.toaster.success(this.translate.instant('Class added Successfully'));
          this.router.navigate(['/admin/class']);
        },
        (err: any) => {
          this.toaster.error(err.error.message);
        }
      );
    } else {
      this.Apiservice.postApi(
        endpoints.updateclass.path + '?id=' + this.routerid,
        this.classForm.value
      ).subscribe(
        (data: any) => {
          this.showMsg = true;
          this.toaster.success(this.translate.instant('Class updated Successfully'));
          this.router.navigate(['/admin/class']);
        },
        (err: any) => {
          this.toaster.error(err.error.message);
        }
      );
    }
  }
}
